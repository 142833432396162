/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { Icon } from '../icon/icon'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors
	id?: string
}) {
	const { t } = useTranslation()
	const errorsToRender = errors?.filter(Boolean)
	if (!errorsToRender?.length) return null
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map((e: any) => (
				<li
					key={e}
					className="mb-1 mt-2 justify-center text-[14px] text-foreground-danger"
				>
					<Icon name="warning-triangle" size="md" className="mr-2" /> {t(e)}
				</li>
			))}
		</ul>
	)
}
